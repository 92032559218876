$primary-color: #005FAA;
$navbar-background: #0A6FBE; // Svetlija nijansa plave za pozadinu navigacionog bara
$nav-link-color: #ffffff; // Bela boja za linkove
$nav-link-active-color: #d9d9d9; // Tamnija nijansa bele za aktivne linkove

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif;
}

header, .mechanism, .prizes, .winners, .rules {
  padding: 60px 0;
}

.bg-primary {
  background-color: $primary-color;
}


.line-background-section {
  background-image: url(../public/Layer_1.png); // Putanja do vaše slike linija
  background-size: cover; // Ili contain, zavisno od efekta koji želite
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 70px; // Podesite visinu sekcije prema potrebi
}

@media (min-width: 1921px) {
  .line-background-section {
    height: 100px; // Podesite visinu sekcije prema potrebi
  }
}

@media (max-width: 992px) {
  .line-background-section {
    height: 40px; // Podesite visinu sekcije prema potrebi
  }
}

.mechanism-section {
  background-color: $primary-color !important;

  @media (max-width: 992px) {
    .font-upercase{
      font-size: 2rem !important;
    }
    .custom-text {
      font-size: 1.2rem !important;
    }
    .text-video{
      font-size: 1rem !important;
    }
    .text-promo{
      font-size: 0.6rem !important;
    }
  }

  .mt5{
    @media (min-width: 1400px) {
      margin-top: 3rem; 
    }

    @media (max-width: 1399px) and (min-width: 992px) {
      margin-top: 4rem; 
    }
  
    @media (max-width: 991px) {
      margin-top: 0; 
    }
  }

  .text-promo{
    font-size: 1rem;
    // text-transform: uppercase;
    font-weight: 450;
    text-align: center;
  }

  .text-video{
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 450;
    text-align: center;
  }
  .font-upercase{
    text-transform: uppercase;
    // font-family: "GothamBoldPravi";
    font-weight: bold;
    color: white;
    text-align: center;
    font-size: 3rem;
  }
  .custom-text {
    text-transform: uppercase;
    margin-left: 20px; // Povećajte razmak između slike i teksta
    font-size: 1.6rem;
    // font-family: "GothamBoldPravi";
    font-weight: bold;
    color: white;
  //   font-family: 'Arial', sans-serif; // Promenite font po potrebi
  }

}

.mechanism h2 {
  font-size: 24px;
  font-weight: bold;
}

.video-container {
  position: relative;
  cursor: pointer;
}

.play-button {
  width: 64px;
  height: 64px;
}

.video-thumbnail img {
  width: 100%;
  height: auto;
}


@media (max-width: 992px) {
  .mechanism img {
    margin-bottom: 20px;
  }
  
  .mechanism .d-flex {
    flex-direction: column;
    text-align: center;

    img {
      margin-bottom: 10px;
    }
  }
}

/* Stilovi za NavigationBar */
.custom-navbar {
  background-color: $navbar-background !important; // Svetlija plava boja pozadine
}

.navbar-logo {
  height: 60px; // Visina logo i grba
  max-height: 100%;
}

.nav-links {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.nav-link {
  font-size: 1.2rem; // Uvećana veličina fonta
  font-weight: bold;
  margin: 0 10px; // Razmak između linkova
  color: $nav-link-color !important; // Bela boja za linkove
}

// .nav-link:hover, .nav-link:focus {
//   color: $nav-link-active-color !important; // Tamnija nijansa bele za hover i fokus
// }

.nav-link.active {
  color: $nav-link-active-color !important; // Tamnija nijansa bele za aktivne linkove
}

.ml-auto {
  margin-left: auto !important;
}

.prizes-section {
  background-color: $primary-color !important;
  position: relative;
  padding: 60px 0;
  background-size: cover;
  background-position: center;

  .text-center-bottom{
    font-size: 0.8rem;
    margin-top: 1%;
    margin-bottom: 3%;
    font-weight: 500;
  }

  @media (max-width: 992px) {
    .text-center-bottom{
    font-size: 0.5rem !important;
    margin-bottom: -5% !important;
    // margin-bottom: 3%;
    margin-top: 0 !important;
  }
}

  .top-wave-line {
    position: absolute;
    top: 7%;
    left: 0;
    width: 50%;
    img{
      max-width: 100%;
      // max-height: auto;
    }
  }

  .bottom-wave-line {
    position: absolute;
    bottom: 4%;
    right: 0;
    width: 50%;
    display: flex;
    justify-content: flex-end;
    img{
      max-width: 100%;
      // max-height: auto;
    }
  }

  @media (max-width: 992px) {
    .top-wave-line,
    .bottom-wave-line {
      display: none;
    }
  }

  .container {
    position: relative;
    z-index: 1;
  }

  .prize img {
    max-width: 100%;
    height: auto;
  }

  .prize-large img {
    width: 80%; // Adjust as needed
  }

  .prize {
    text-align: center;
    color: white;
  }

  .prize-small {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  @media (max-width: 768px) {
    .prizes-section .prize {
      margin-bottom: 20px;
    }
  }

  @media (max-width: 576px) {
    .prizes-section .prize-large img {
      width: 100%; // Adjust as needed
    }
  }
}

.winners-section {
  background-color: #005FAA;
  background-image: url('../public/konfete.png'); // Putanja do slike konfeta
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 60px 0;
  position: relative;
  text-align: center;

  @media (max-width: 992px) {
    padding: 20px 0; // Smanjite padding za mobilne uređaje
  }

  .winners-header {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-bottom: 20px;

    .week-dropdown {
      background-color: #00aaff;
      color: white;
      border: none;
      padding: 5px 10px;
      border-radius: 5px;
      // margin-left: 20px;
    }
  }

  .winners-table-wrapper {
    position: relative;

    .table-background {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      height: auto; 
    }
    .no-winners-message{
      text-transform: uppercase;
      // margin-left: 20px; // Povećajte razmak između slike i teksta
      font-size: 2rem;
      // font-family: "GothamBoldPravi";
      font-weight: bold;
      color: white;
    }
    @media (max-width: 992px) {
      .no-winners-message {
        font-size: 1rem;
      }
    }

    .winners-table {
      position: absolute;
      top: 15%;
      left: 7%;
      width: 86%;
      height: 79%;
      overflow-y: auto;

      table {
        width: 100%;
        border-collapse: collapse;

        td {
          padding: 10px;
          text-align: left;
          // border-bottom: 1px solid #ddd;
          vertical-align: middle;
        }
        @media (max-width: 992px) {
          td {
            padding: 0 !important;
          }
        }

        td:first-child {
          text-align: left; // Left align for the first column (index)
        }

        td:nth-child(2){
          display: flex;
          justify-content: center;
          text-align: center; // Center align for the second column (names)
          margin-left: 78%;
        }
        @media (max-width: 992px) {
          td:nth-child(2) {
            margin-left: 100%;
          }
        }

        td:last-child {
          // display: flex;
          // justify-content: right;
          text-align: right !important; // Center align for the second column (names)
          // margin-left: 28%;
        }

        .even {
          background-color: rgba(255, 255, 255, 0.5); // Light background for even rows
        }
      }
    }
  }
  .custom-font {
    text-transform: uppercase;
    // margin-left: 20px; // Povećajte razmak između slike i teksta
    font-size: 1.2rem;
    // font-family: "GothamBoldPravi";
    font-weight: bold;
    color: white;
  }
  @media (max-width: 992px) {
    .custom-font{
      font-size: 0.7rem;
    }
  }
}

.rules-contact-section {
  background-color: #005FAA; // Plava pozadina
  color: white; // Bela boja teksta
  padding: 60px 0;
  text-align: center;
  font-weight: bold;

  p {
    font-size: 1.2rem;
  }

  @media (max-width: 992px) {
    p{
      font-size: 1rem !important;
    }
  }
  .contact-text{
    text-transform: uppercase;
    font-size: 1.8rem;
    // font-family: "GothamBoldPravi";
    font-weight: bold;
    color: white;
    // margin-bottom: 20px;
  }
  .rules-button {
    background-color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 8px;
    color: #083f79;
    text-transform: uppercase;
    margin-top: 4px;

    &:hover {
      background-color: #0088cc;
      color: white;
    }
  }

  @media (max-width: 992px) {
    .rules-button {
      background-color: white;
      border: none;
      padding: 5px 10px;
      font-size: 1rem;
      font-weight: bold;
      border-radius: 8px;
      color: #083f79;
      text-transform: uppercase;
      margin-top: 4px;
  
      &:hover {
        background-color: #0088cc;
        color: white;
      }
    }
  }
  .contact-margin{
    margin-top: 6rem !important;
  }

  .contact-info {
    background-color: #083f79; // Tamnija plava pozadina za kontakt deo
    padding: 20px;
    border-radius: 10px;
    margin-top: 10px;

    // h2 {
    //   margin-bottom: 20px;
    // }

    p {
      margin: 5px 0;
      font-size: 1.4rem;
    }
  }

  @media (max-width: 992px) {
    .contact-text{
      font-size: 1.3rem;
      margin-bottom: 10px !important;
    }
    .contact-margin{
      margin-top: 2rem !important;
    }
        p {
        margin: 5px 0;
        font-size: 1.1rem;
      }
    .contact-info {
      max-width: 300px; // Dodajte ovu liniju za smanjenje širine
      margin: 0 auto; // Centriranje elementa
  
      p {
        margin: 5px 0;
        font-size: 1.1rem;
      }
    }
  }
  .text-center-bottom{
    font-size: 0.7rem !important;
    margin-top: 10%;
    margin-bottom: -4% !important;
  }

  @media (max-width: 992px) {
    .text-center-bottom{
    font-size: 0.5rem !important;
    // margin-bottom: -10% !important;
  }
}
}
